import querystring from'querystring';
import axiosWrapper from '../auth/authAxiosRequest';
import { getAPIVariable } from '../auth/authAxiosRequest'
import { CategoryToApi, SequencedCategory } from "../models/Category";
import {
  ExportProductReqParams,
  PaginatedAssetsReqParams,
  PaginatedProductReqParams,
  ProductToApi
} from "../models/Product";
import { Brand } from "../models/Brand";
import { ReorderedWarehouse, Warehouse } from "../models/Warehouse";
import {
  BillingImport,
  CategoryImport,
  CustomerImportToApi,
  ImportFormValues, ProductDocImport, ProductGalleryImport,
  ProductImportToApi,
  TranslationImport
} from "../models/Import";
import { Coupon } from "../models/Coupon";
import { SearchConfig } from "../models/Search";
import { Customer } from "../models/Customer";
import { Login, ResetLoginPWBody } from "../models/Login";
import { ShippingMethod } from "../models/ShippingMethod";
import { Term } from "../models/Term";
import { Translation } from "../models/Translation";
import { ShippingRate } from "../models/ShippingRate";
import { CustomerNumber } from "../models/CustomerNumber";
import { ContentGroup } from "../models/ContentGroup";
import {
  ACHPaginationParams,
  AnalyticsDateRange, GoogleAnalyticsConfig, FeatureFlagToApi,
  OrderHistoryPaginationParams, SharedACHPaginationParams, AzureUser
} from "../models/App";
import { BaseResource, ImageOverlayToApi, LibraryAsset } from "../models/Resource";
import { Country, CustomField, State, WebControl } from "../models/Websetup";
import { SalesRepToApi, SalesRepType } from "../models/SalesRep";
import { ShipTo } from "../models/ShipTo";
import { ACHExportStatus } from "../models/Analytics";
import { ExtraBilling } from "../models/ExtraBilling";
import { ApprovalGroup } from "../models/ApprovalGroup";
import { LoginBudgetToApi } from "../models/LoginBudget"
import { convertDatesForGA4 } from "../views/Google/helpers/google-analytics-init";
import { CompanyInfoToAPI } from "../models/CompanyInfo";

const url = getAPIVariable();

// response.data
const fetchCatalogs = () => {
  return axiosWrapper({ method: 'GET', url: `/v2/catalogs` });
};

// response.data
const fetchCatalog = (catalog: string) => {
  return axiosWrapper({ method: 'GET', url: `/v2/catalogs/${catalog}` });
}

// response.data
const fetchCategories = (catalog: string) => axiosWrapper({
  method: 'GET',
  url: `v2/catalogs/${catalog}/categories`
});

// response.data
const fetchCategory = (catalog: string, categoryID: number | string) => axiosWrapper({
  method: 'GET',
  url: `v2/catalogs/${catalog}/categories/${categoryID}`
});

// response.data
const fetchCategoryTree = (catalog: string) => {
  return axiosWrapper({
    method: 'GET',
    url: `v2/catalogs/${catalog}/categories/tree`
  });
//   return Promise.resolve({data: categoryTreeData})
}


// empty response
const deleteCategory = (catalog: string, categoryID: number | string) => axiosWrapper({
  method: 'DELETE',
  url: `v2/catalogs/${catalog}/categories/${categoryID}`
});

// empty response
const updateCategory = (catalog: string, categoryID: number, values: CategoryToApi) => axiosWrapper({
  method: 'PATCH',
  url: `v2/catalogs/${catalog}/categories/${categoryID}`,
  data: { ...values }
});

// response
const createCategory = (catalog: string, values: CategoryToApi) => axiosWrapper({
  method: 'POST',
  url: `v2/catalogs/${catalog}/categories`,
  data: { ...values }
});

// empty response
const reorderCategories = (catalog: string, categoryStructure: SequencedCategory[]) => axiosWrapper({
  method: 'PATCH',
  url: `v2/catalogs/${catalog}/categories`,
  data: [ ...categoryStructure ]
});

// reorders subcategories by category name
const alphabetizeCategories = (catalog: string) => axiosWrapper({method: 'POST', url: `v2/catalogs/${catalog}/categories/_alphabetize`, data: {}});

// empty response
// if >=1 product belonging to a category has an assigned image, adds that image to categories that do not have an assigned image
const propagateCatImages = (catalog: string) => axiosWrapper({method: 'POST', url: `v2/catalogs/${catalog}/categories/_propagateImages`, data: {}});

// for testing race condition between fetchPagedProducts/fetchProduct
// function wait(milliseconds: number) {
//   return new Promise(resolve => setTimeout(resolve, milliseconds));
// }

// response.data
const fetchPagedProducts = (catalog: string, options: PaginatedProductReqParams) => {
  // @ts-ignore
  const optionsString = querystring.stringify(options);
  //pageNumber, pageSize, sortColumn, searchString
  return axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/products?${optionsString}` });

  // testing race condition
  // return wait(1000).then(() => {
  //   return axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/products?${optionsString}` });
  // })
};

// response.data
const fetchProduct = (catalog: string, webID: number | string) => {
  return axiosWrapper({ method: 'GET', url: `v3/catalogs/${catalog}/products/${webID}` });
};

// response.data
const checkPartNumberUnique = (catalog: string, partNumber: string) => axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/products/_exists?partNumber=${partNumber}` });

// response.data
const checkFriendlyUrlUnique = (catalog: string, friendlyUrl: string) => axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/products/_friendlyUrlNameExists?friendlyUrlName=${friendlyUrl}` });
// Promise.resolve({"data": {"data": false}})

// response.data
const createProduct = (catalog: string, productData: ProductToApi) => {
  return axiosWrapper({
    method: 'POST',
    url: `v3/catalogs/${catalog}/products`,
    data: productData
  });
};

// empty response
const updateProduct = (
  catalog: string,
  webID: number,
  productData: ProductToApi
) => {
  return axiosWrapper({
    method: 'PATCH',
    url: `v3/catalogs/${catalog}/products/${webID}`,
    data: productData
  });
};

// empty response
const deleteProduct = (catalog: string, webID: number | string) => axiosWrapper({ method: 'DELETE', url: `v2/catalogs/${catalog}/products/${webID}` });

// empty response
const deleteProducts = (catalog: string, webIdArray: number[]) => {
  return axiosWrapper({ method: 'POST', url: `v2/catalogs/${catalog}/products/_delete`, data: webIdArray });
};

// response
const exportProducts = (catalog: string, options: ExportProductReqParams) => {
  // @ts-ignore
  const optionsString = querystring.stringify(options);
  return axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/products/_export?${optionsString}`, responseType: 'blob'});
}

const exportRelatedProducts = (catalog: string) => {
  return axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/products/relatedProducts/_export`, responseType: 'blob'});
}

const exportVariantProducts = (catalog: string) => {
  return axiosWrapper({ method: 'GET', url: `v3/catalogs/${catalog}/products/variantProducts/_export`, responseType: 'blob'});
}

const exportGalleryImages = (catalog: string) => {
  return axiosWrapper({ method: 'GET', url: `v3/catalogs/${catalog}/products/galleryImages/_export`, responseType: 'blob'});
}

// response
const exportCategories = (catalog: string) => axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/categories/_export`, responseType: 'blob'});

// response.data
const fetchBrands = (catalog: string) => axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/brands` });

// response.data
const fetchBrand = (catalog: string, brandId: number | string) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/brands/${brandId}` });
};

// empty response
const updateBrand = (catalog: string, brandId: number, brandData: Brand) => axiosWrapper({ method: 'PATCH', url: `/catalogs/${catalog}/brands/${brandId}`, data: brandData });

// response.data (w/ complete brand data)
const createBrand = (catalog: string, brandData: Brand) => axiosWrapper({ method: 'POST', url: `/catalogs/${catalog}/brands`, data: brandData });

// empty response
const deleteBrand = (catalog: string, brandId: number | string) => axiosWrapper({ method: 'DELETE', url: `/catalogs/${catalog}/brands/${brandId}` });

// response
const fetchProductBackups = (catalog: string) => axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/backups` });

// response
const fetchProductBackup = (catalog: string, backupId: number | string) => axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/backups/${backupId}` });

// empty response
const createProductBackup = (catalog: string, data: {notes: string}) => axiosWrapper({ method: 'POST', url: `/catalogs/${catalog}/backups`, data });

// assume empty response
const restoreProductBackup = (catalog: string, backupId: number) => axiosWrapper({ method: 'POST', url: `/catalogs/${catalog}/backups/${backupId}/_restore` });

// empty response
const deleteProductBackup = (catalog: string, backupId: number | string) => axiosWrapper({ method: 'DELETE', url: `/catalogs/${catalog}/backups/${backupId}` });

// response.data
const fetchWarehouses = () => axiosWrapper({ method: 'GET', url: `/Warehouses` });

// assume response.data (endpoint currently throwing 500)
const fetchWarehouse = (warehouseId: number | string) => axiosWrapper({ method: 'GET', url: `/warehouses/${warehouseId}` });

// response (w/ complete whse data)
const createWarehouse = (data: Warehouse) => axiosWrapper({ method: 'POST', url: `/warehouses`, data });

// assume empty response (fetchWarehouse currently throwing 500)
const updateWarehouse = (warehouseId: number, data: Warehouse) => axiosWrapper({ method: 'PATCH', url: `/warehouses/${warehouseId}`, data });

// empty response
const deleteWarehouse = (warehouseId: number | string) => axiosWrapper({ method: 'DELETE', url: `/warehouses/${warehouseId}` });

const reorderWarehouses = (warehouseStructure: ReorderedWarehouse[]) => {
  return axiosWrapper({
    method: 'PATCH',
    url: `/warehouses`,
    data: [ ...warehouseStructure ]
  });
};

// response
const uploadSpreadsheet = (importSpreadsheet: File, onProgress: Function) => {
  const formData = new FormData();
  // 3rd param filename needed for requests in Edge
  // otherwise user's entire local filepath is POSTed
  formData.append('attachment', importSpreadsheet, importSpreadsheet.name);
  return axiosWrapper({
    method: 'POST',
    url: `${url}/Attachments`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: ({ total, loaded }: {total: number, loaded: number}) => {
      onProgress({ percent: Math.round(loaded / total * 100) });
    }
  });
};

// used when importing products from .xslx
// empty response
const mapProductFieldsFromUpload = (
  catalog: string,
  spreadsheetId: number,
  activeSheet: string,
  values: ImportFormValues<ProductImportToApi>,
  callV3ProductImport: boolean
) => {
  const body = {
    ...values,
    attachmentId: spreadsheetId,
    worksheetName: activeSheet
  };

  const endpoint = !!callV3ProductImport ? `${url}/v3/catalogs/${catalog}/Products/_import` : `${url}/v2/catalogs/${catalog}/Products/_import`

  return axiosWrapper({
    method: 'POST',
    url: endpoint,
    data: body,
    headers: { 'Content-Type': 'application/json-patch+json' }
  });
};

// used when importing categories from .xslx
// empty response
const mapCategoryFieldsFromUpload = (
  catalog: string,
  spreadsheetId: number,
  activeSheet: string,
  values: ImportFormValues<CategoryImport>
) => {
  const body = {
    ...values,
    attachmentId: spreadsheetId,
    worksheetName: activeSheet
  };

  return axiosWrapper({
    method: 'POST',
    url: `${url}/v2/catalogs/${catalog}/Categories/_import`,
    data: body,
    headers: { 'Content-Type': 'application/json-patch+json' }
  });
};

// used when importing customers/logins from .xslx
// empty response
const mapCustomerFieldsFromUpload = (
  spreadsheetId: number,
  activeSheet: string,
  values: ImportFormValues<CustomerImportToApi>
) => {
  const body = {
    ...values,
    attachmentId: spreadsheetId,
    worksheetName: activeSheet
  };
  return axiosWrapper({
    method: 'POST',
    url: `${url}/Customers/v2/_import`,
    data: body,
    headers: { 'Content-Type': 'application/json-patch+json' }
  });
};

// used when importing translations from .xslx
// empty response
const mapTranslationFieldsFromUpload = (
  catalog: string,
  spreadsheetId: number,
  activeSheet: string,
  values: ImportFormValues<TranslationImport>
) => {
  const body = {
    ...values,
    attachmentId: spreadsheetId,
    worksheetName: activeSheet
  };
  return axiosWrapper({
    method: 'POST',
    url: `${url}/catalogs/${catalog}/Translations/_import`,
    data: body,
    headers: { 'Content-Type': 'application/json-patch+json' }
  });
};

// used when importing cost centers from .xslx
// empty response
const mapBillingFieldsFromUpload = (
  spreadsheetId: number,
  activeSheet: string,
  values: ImportFormValues<BillingImport>
) => {
  const body = {
    ...values,
    attachmentId: spreadsheetId,
    worksheetName: activeSheet
  };
  return axiosWrapper({
    method: 'POST',
    url: `${url}/costcenters/_import`,
    data: body,
    headers: { 'Content-Type': 'application/json-patch+json' }
  });
};

// used when mapping product documents via .xslx
// empty response
const mapProductDocFieldsFromUpload = (
  catalog: string,
  spreadsheetId: number,
  activeSheet: string,
  values: ImportFormValues<ProductDocImport>
) => {
  const body = {
    ...values,
    attachmentId: spreadsheetId,
    worksheetName: activeSheet
  };
  return axiosWrapper({
    method: 'POST',
    url: `${url}/catalogs/${catalog}/productDocuments/_import`,
    data: body,
    headers: { 'Content-Type': 'application/json-patch+json' }
  });
};

// used when mapping product gallery images via .xslx
// empty response
const mapProductGalleryFieldsFromUpload = (
  catalog: string,
  spreadsheetId: number,
  activeSheet: string,
  values: ImportFormValues<ProductGalleryImport>
) => {
  const body = {
    ...values,
    attachmentId: spreadsheetId,
    worksheetName: activeSheet
  };
  return axiosWrapper({
    method: 'POST',
    url: `${url}/catalogs/${catalog}/galleryImages/_import`,
    data: body,
    headers: { 'Content-Type': 'application/json-patch+json' }
  });
};

// url used in tus upload POST - wrapping handled in component,
// doesn't directly use axiosWrapper
// empty response; returns 201 on success; throws 404 on new dev API
const tusImageUpload = () => `${url}/catalog-images`;

// empty response; returns 201 on success; throws 404 on new dev API
const tusDocumentUpload = () => `${url}/documents`;

// delete bulk-uploaded resource created on API
// url is defined on bulk upload, will be supplied by onSuccess handler
// empty response
const deleteBulkUpload = (url: string) => axiosWrapper({
  headers: {
    'Tus-Resumable': '1.0.0'
  },
  method: 'DELETE',
  url
});

// response
// const fetchCoupons = (catalog: string, couponType: string) => axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/${couponType}-coupons` });
const fetchCoupons = (catalog: string) => axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/coupons` });

// response
const fetchCoupon = (catalog: string, couponType: string, couponID: number | string) => axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/${couponType}Coupons/${couponID}` });

// response (w/ complete coupon data)
const createProductCoupon = (
  catalog: string,
  productCouponData: Coupon
) => {
  return axiosWrapper({
    method: 'POST',
    url: `v2/catalogs/${catalog}/ProductCoupons`,
    data: productCouponData
  });
};

// empty response
const updateProductCoupon = (
  catalog: string,
  couponId: number,
  productCouponData: Coupon
) => {
  return axiosWrapper({
    method: 'PUT',
    url: `v2/catalogs/${catalog}/ProductCoupons/${couponId}`,
    data: productCouponData
  });
};

// response (w/ complete coupon data)
const createOrderCoupon = (
  catalog: string,
  orderCouponData: Coupon
) => {
  return axiosWrapper({
    method: 'POST',
    url: `v2/catalogs/${catalog}/OrderCoupons`,
    data: orderCouponData
  });
};

// empty response
const updateOrderCoupon = (
  catalog: string,
  couponId: number,
  orderCouponData: Coupon
) => {
  return axiosWrapper({
    method: 'PUT',
    url: `v2/catalogs/${catalog}/OrderCoupons/${couponId}`,
    data: orderCouponData
  });
};
// empty response
const deleteCoupon = (catalog: string, couponType: string, couponId: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `v2/catalogs/${catalog}/${couponType}Coupons/${couponId}` });
};

// response.data
const fetchSearchConfig = (catalog: string) => {
  return axiosWrapper({ method: 'GET', url: `/v2/catalogs/${catalog}/search-configuration` });
};

// empty response
const updateSearchConfig = (
  catalog: string,
  searchSettingsPatch: SearchConfig
) => {
  return axiosWrapper({ method: 'PATCH', url: `/v2/catalogs/${catalog}/search-configuration`, data: searchSettingsPatch });
};

// empty response
const indexSearch = (catalog: string) => {
  return axiosWrapper({ method: 'POST', url: `/v2/catalogs/${catalog}/products/_index` });
};

// response.data
const productSearch = (catalog: string, partNumber: string) => {
  return axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/Products?q=${partNumber}` });
};

// response
const fetchCustomers = () => {
  return axiosWrapper({ method: 'GET', url: `/Customers` });
};

// response
const fetchCustomer = (customerId: number | string) => {
  return axiosWrapper({ method: 'GET', url: `/Customers/${customerId}` });
};

// empty response
const createCustomer = (values: Customer) => {
  return axiosWrapper({ method: 'POST', url: `/Customers`, data: { ...values } });
};

// empty response
const updateCustomer = (values: Customer) => {
  return axiosWrapper({ method: 'PUT', url: `/Customers/${values.id}`, data: { ...values } });
};

// empty response
const deleteCustomer = (customerId: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `/Customers/${customerId}` });
};

// response.data
const fetchLogins = (custId?: number | string) => {
  return axiosWrapper({ method: 'GET', url: `/v2/Logins${custId ? `?customerId=${custId}` : ''}` });
};

// response.data
const fetchLogin = (userId: string) => {
  return axiosWrapper({ method: 'GET', url: `/v2/Logins/${userId}` });
};

// empty response
const createLogin = (values: Login) => {
  return axiosWrapper({ method: 'POST', url: `/v3/Logins`, data: { ...values } });
};

// empty response
const updateLogin = (values: Login) => {
  return axiosWrapper({ method: 'PATCH', url: `/v3/Logins/${values.userId}`, data: { ...values } });
};

// empty response
const deleteLogin = (userId: string) => {
  return axiosWrapper({ method: 'DELETE', url: `/Logins/${userId}` });
};

const resetLoginPW = (values: ResetLoginPWBody) => {
  return axiosWrapper({method: 'POST', url: `/Logins/ResetPassword`, data: {...values}})
  // test success response
  // return Promise.resolve()
  // test error response
  // return Promise.reject({data: 'error!'})
}

// response
const fetchShippingMethods = (catalog: string) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/shipping-methods` });
};

// response
const fetchShippingMethod = (catalog: string, id: number | string) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/shipping-methods/${id}` });
};

// empty response
const createShippingMethod = (catalog: string, values: ShippingMethod) => {
  return axiosWrapper({ method: 'POST', url: `/catalogs/${catalog}/shipping-methods`, data: { ...values } });
};

// empty response
const updateShippingMethod = (catalog: string, values: ShippingMethod) => {
  return axiosWrapper({ method: 'PUT', url: `/catalogs/${catalog}/shipping-methods/${values.id}`, data: { ...values } });
};

// empty response
const deleteShippingMethod = (catalog: string, id: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `/catalogs/${catalog}/shipping-methods/${id}` });
};

// response.data
const fetchCatalogTerms = (catalog: string) => {
  return axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/terms` });
};

// response.data
const fetchTerm = (catalog: string, id: number | string) => {
  return axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/terms/${id}` });
};

// empty response
const createTerm = (catalog: string, values: Term) => {
  return axiosWrapper({ method: 'POST', url: `v2/catalogs/${catalog}/terms`, data: { ...values } });
};

// empty response
const updateTerm = (catalog: string, id: number, values: Term) => {
  return axiosWrapper({ method: 'PATCH', url: `v2/catalogs/${catalog}/terms/${id}`, data: { ...values } });
};

// empty response
const deleteTerm = (catalog: string, id: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `v2/catalogs/${catalog}/terms/${id}`});
};

// response
const fetchTranslations = (catalog: string) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/Translations` });
};

// response
const fetchTranslation = (catalog: string, translationId: number | string) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/Translations/${translationId}` });
};

// empty response
const createTranslation = (catalog: string, values: Translation) => {
  return axiosWrapper({ method: 'POST', url: `/catalogs/${catalog}/Translations`, data: { ...values } });
};

// empty response
const updateTranslation = (catalog: string, translationId: number, values: Translation) => {
  return axiosWrapper({ method: 'PUT', url: `/catalogs/${catalog}/Translations/${translationId}`, data: { ...values } });
};

// empty response
const deleteTranslation = (catalog: string, translationId: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `/catalogs/${catalog}/Translations/${translationId}` });
};

// assume response
const fetchShippingRates = (catalog: string) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/shipping-rates` });
};

// assume response
const fetchShippingRate = (catalog: string, rateId: number | string) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/shipping-rates/${rateId}` });
};

// assume empty response
const createShippingRate = (catalog: string, values: ShippingRate) => {
  return axiosWrapper({ method: 'POST', url: `v2/catalogs/${catalog}/shipping-rates`, data: { ...values } });
};

// assume empty response
const updateShippingRate = (catalog: string, rateId: number, values: ShippingRate) => {
  return axiosWrapper({ method: 'PUT', url: `v2/catalogs/${catalog}/shipping-rates/${rateId}`, data: { ...values } });
};

// assume empty response
const deleteShippingRate = (catalog: string, rateId: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `/catalogs/${catalog}/shipping-rates/${rateId}` });
};

const fetchShipTos = () => {
  return axiosWrapper({ method: 'GET', url: `/shipTos` });
}

const fetchShipTo = (shipToId: number | string) => {
  return axiosWrapper({ method: 'GET', url: `/shipTos/${shipToId}` });
}

const createShipTo = (values: ShipTo) => {
  return axiosWrapper({ method: 'POST', url: `/shipTos`, data: { ...values } });
}

const updateShipTo = (addressId: number, values: ShipTo) => {
  return axiosWrapper({ method: 'PUT', url: `/shipTos/${addressId}`, data: { ...values } });
}

const deleteShipTo = (shipToId: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `/shipTos/${shipToId}` });
}

// assume response
const fetchCustomerNums = (catalog: string) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/CustomerNumbers/GetAllCustomerNumbers` });
};

// assume response
const fetchCustomerNum = (catalog: string, custNumId: number | string) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/CustomerNumbers/GetByPkid/${custNumId}` });
};

// assume response
const fetchCustNumsByCustId = (catalog: string, custId: number) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/CustomerNumbers/GetByCustID/${custId}` });
};

// assume empty response
const updateCustomerNum = (catalog: string, values: CustomerNumber) => {
  return axiosWrapper({ method: 'POST', url: `/catalogs/${catalog}/CustomerNumbers/UpdateCustomerNumber`, data: { ...values } });
};

// assume empty response
const createCustomerNum = (catalog: string, values: CustomerNumber) => {
  return axiosWrapper({ method: 'POST', url: `/catalogs/${catalog}/CustomerNumbers/AddCustomerNumber`, data: { ...values } });
};

// assume empty response
const deleteCustomerNum = (catalog: string, custNumId: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `/catalogs/${catalog}/CustomerNumbers/DeleteCustomerNumberByPkid/${custNumId}` });
};

// response
const fetchContentGroups = () => {
  return axiosWrapper({ method: 'GET', url: `/content-groups` });
};

// response
const fetchContentGroup = (groupId: number | string) => {
  return axiosWrapper({ method: 'GET', url: `/content-groups/${groupId}` });
};

// response (containing new group data)
const createContentGroup = (values: ContentGroup) => {
  return axiosWrapper({ method: 'POST', url: `/content-groups`, data: { ...values } });
};

// empty response
const updateContentGroup = (groupId: number, values: ContentGroup) => {
  return axiosWrapper({ method: 'PUT', url: `/content-groups/${groupId}`, data: { ...values } });
};

// empty response
const deleteContentGroup = (groupId: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `/content-groups/${groupId}` });
};

// response
const getOrderHistory = (catalog: string, pageOptions: OrderHistoryPaginationParams) => {
  const optionsString = querystring.stringify(pageOptions as Record<string, any>);
  return axiosWrapper({ method: 'GET', url: `/v2/catalogs/${catalog}/orders?${optionsString}` })
};

// assume response
const getAbandonedCarts = (catalog: string, dateRange: AnalyticsDateRange, showEmptyEmails: boolean) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/abandoned-carts?dateRange=${dateRange.startDate},${dateRange.endDate}&showEmptyEmails=${showEmptyEmails}` })
};

// response ({columnDefs[], rowData[]})
const getLoginReports = (catalog: string, dateRange: AnalyticsDateRange) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/reports?dateRange=${dateRange.startDate},${dateRange.endDate}` })
};

// response ({columnDefs[], rowData[]})
const getFailedSearches = (catalog: string, dateRange: AnalyticsDateRange) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/failed-searches?dateRange=${dateRange.startDate},${dateRange.endDate}` })
};

// response ({columnDefs[], rowData[]})
const getSuccessfulSearches = (catalog: string, dateRange: AnalyticsDateRange) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/successful-searches?dateRange=${dateRange.startDate},${dateRange.endDate}` })
};

// response
const getUsageReports = (catalog: string, dateRange: AnalyticsDateRange) => {
  return axiosWrapper({ method: 'GET', url: `/v2/catalogs/${catalog}/statistics?dateRange=${dateRange.startDate},${dateRange.endDate}` })
};

const getACHReports = (catalog: string, dateRange: AnalyticsDateRange) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/v2/ach?dateRange=${dateRange.startDate},${dateRange.endDate}` })
};

const getPaginatedACHReports = (catalog: string, pageOptions: ACHPaginationParams) => {
  return axiosWrapper({ method: 'POST', url: `/catalogs/${catalog}/v3/ach`, data: {...pageOptions} })
}

const updateACHReportStatus = (catalog: string, exportedIds: ACHExportStatus[]) => {
  return axiosWrapper({ method: 'PATCH', url: `/catalogs/${catalog}/ACHExport`, data: {"achIds": [ ...exportedIds ]}  })
}

const exportPaginatedACHReports = (catalog: string, pageOptions: SharedACHPaginationParams) => {
  return axiosWrapper({ method: 'POST', url: `/catalogs/${catalog}/v3/ach/export`, data: {...pageOptions}, responseType: 'blob' })
}

// response
const getAnalyticsConfig = (catalog: string) => {
  return axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/google-analytics/configuration` })
};

// empty response
const updateAnalyticsConfig = (catalog: string, values: GoogleAnalyticsConfig) => {
  return axiosWrapper({ method: 'PUT', url: `v2/catalogs/${catalog}/google-analytics/configuration`, data: { ...values } })
};

// empty response
const deleteAnalyticsConfig = (catalog: string) => {
  return axiosWrapper({ method: 'DELETE', url: `v2/catalogs/${catalog}/google-analytics/configuration`})
};
// response
const getDashboardCardAnalytics = (catalog: string, dateRange: AnalyticsDateRange) => {
    const ga4DateRange = convertDatesForGA4(dateRange)
    return axiosWrapper({ method: 'POST', url: `v2/catalogs/${catalog}/google-analytics`, data: {
        "dimensions":[],
        "metrics":[
          {
            "name":"purchaseRevenue"
          },
          {
            "name": "averagePurchaseRevenuePerUser"
          },
          {
            "name": "ecommercePurchases"
          },
          {
            "name": "activeUsers"
          },
          {
            "name": "newUsers"
          }
        ],
        "dateRanges":[
          {
            "startDate":ga4DateRange.startDate,
            "endDate":ga4DateRange.endDate
          }
        ],
        "orderBys":[]
      }})
};

// response
const getTopRevenue = (catalog: string, rowCount: number) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/top-revenue?limit=${rowCount}` })
};

// response
const fetchAudienceOverviewData = (catalog: string, dateRange: AnalyticsDateRange) => {
    const ga4DateRange = convertDatesForGA4(dateRange)
    return axiosWrapper({ method: 'POST', url: `v2/catalogs/${catalog}/google-analytics`, data: {
        "dimensions":[
          {
            "name":"date"
          }
        ],
        "metrics":[
          {
            "name":"sessions"
          },
          {
            "name":"totalUsers"
          },
          {
            "name":"newUsers"
          },
          {
            "name":"bounceRate"
          },
        ],
        "dateRanges":[
          {
            "startDate":ga4DateRange.startDate,
            "endDate":ga4DateRange.endDate
          }
        ],
        "orderBys":[
          {
            "dimension":{
              "dimensionName":"date"
            },
            "desc": false
          }
        ]
      }})
};

// response
const fetchGASessionInfoCountry = (catalog: string, dateRange: AnalyticsDateRange) => {
    const ga4DateRange = convertDatesForGA4(dateRange)
    return axiosWrapper({ method: 'POST', url: `v2/catalogs/${catalog}/google-analytics`, data: {
        "dimensions":[
          {
            "name":"region"
          }
        ],
        "metrics":[
          {
            "name":"sessions"
          }
        ],
        "dateRanges":[
          {
            "startDate":ga4DateRange.startDate,
            "endDate":ga4DateRange.endDate
          }
        ],
        "orderBys":[
          {
            "metric":{
              "metricName":"sessions"
            },
            "desc": true
          }
        ]
      }})
};

// response
const fetchGASessionInfoBrowser = (catalog: string, dateRange: AnalyticsDateRange) => {
    const ga4DateRange = convertDatesForGA4(dateRange)
    return axiosWrapper({ method: 'POST', url: `v2/catalogs/${catalog}/google-analytics`, data: {
        "dimensions":[
          {
            "name":"browser"
          }
        ],
        "metrics":[
          {
            "name":"sessions"
          }
        ],
        "dateRanges":[
          {
            "startDate":ga4DateRange.startDate,
            "endDate":ga4DateRange.endDate
          }
        ],
        "orderBys":[
          {
            "metric":{
              "metricName":"sessions"
            },
            "desc": true
          }
        ]
      }})
};

// response
const fetchGASessionInfoDevice = (catalog: string, dateRange: AnalyticsDateRange) => {
    const ga4DateRange = convertDatesForGA4(dateRange)
    return axiosWrapper({ method: 'POST', url: `v2/catalogs/${catalog}/google-analytics`, data: {
        "dimensions":[
          {
            "name":"deviceCategory"
          }
        ],
        "metrics":[
          {
            "name":"sessions"
          }
        ],
        "dateRanges":[
          {
            "startDate":ga4DateRange.startDate,
            "endDate":ga4DateRange.endDate
          }
        ],
        "orderBys":[
          {
            "metric":{
              "metricName":"sessions"
            },
            "desc": true
          }
        ]
      }})
};

// response
const fetchGAWebsiteTrafficData = (catalog: string, dateRange: AnalyticsDateRange) => {
    const ga4DateRange = convertDatesForGA4(dateRange)
    return axiosWrapper({ method: 'POST', url: `v2/catalogs/${catalog}/google-analytics`, data: {
        "dimensions":[
          {
            "name":"pagePath"
          }
        ],
        "metrics":[
          {
            "name":"screenPageViews"
          },
          {
            "name":"sessions"
          },
          {
            "name":"userEngagementDuration"
          }
        ],
        "dateRanges":[
          {
            "startDate":ga4DateRange.startDate,
            "endDate":ga4DateRange.endDate
          }
        ],
        "orderBys":[
          {
            "metric":{
              "metricName":"screenPageViews"
            },
            "desc": true
          }
        ]
      }})
};

// response
const fetchGATopRevenueProducts = (catalog: string, dateRange: AnalyticsDateRange, requestedRows: number) => {
    const ga4DateRange = convertDatesForGA4(dateRange)
    return axiosWrapper({ method: 'POST', url: `v2/catalogs/${catalog}/google-analytics`, data: {
        "dimensions":[
          {
            "name":"itemId"
          },
          {
            "name":"itemName"
          }
        ],
        "metrics":[
          {
            "name": "itemsPurchased"
          },
          {
            "name":"itemRevenue"
          }
        ],
        "dateRanges":[
          {
            "startDate":ga4DateRange.startDate,
            "endDate":ga4DateRange.endDate
          }
        ],
        "orderBys":[
          {
            "metric":{
              "metricName":"itemRevenue"
            },
            "desc": true
          }
        ],
        "limit": requestedRows
      }})
};

// response
const fetchEcommerceOverviewData = (catalog: string, metric1: string, metric2: string, dateRange: AnalyticsDateRange) => {
    const ga4DateRange = convertDatesForGA4(dateRange)
    return axiosWrapper({ method: 'POST', url: `v2/catalogs/${catalog}/google-analytics`, data: {
        "dimensions":[
          {
            "name":"date"
          }
        ],
        "metrics":[
          {
            "name": `${metric1}`
          }
        ],
        "dateRanges":[
          {
            "startDate":ga4DateRange.startDate,
            "endDate":ga4DateRange.endDate
          }
        ],
        "orderBys":[
          {
            "dimension":{
              "dimensionName":"date"
            },
            "desc": false
          }
        ]
      }})
};

// response
const fetchProductPerformanceData = (catalog: string, dateRange: AnalyticsDateRange) => {
    const ga4DateRange = convertDatesForGA4(dateRange)
    return axiosWrapper({ method: 'POST', url: `v2/catalogs/${catalog}/google-analytics`, data: {
        "dimensions":[
          {
            "name":"itemId"
          },
          {
            "name":"itemName"
          }
        ],
        "metrics":[
          {
            "name": "itemsPurchased"
          },
          {
            "name":"itemRevenue"
          }
        ],
        "dateRanges":[
          {
            "startDate":ga4DateRange.startDate,
            "endDate":ga4DateRange.endDate
          }
        ],
        "orderBys":[
          {
            "metric":{
              "metricName":"itemRevenue"
            },
            "desc": true
          }
        ]
      }})
};

const fetchAvgPageLoadData = (catalog: string, dateRange: AnalyticsDateRange) => {
  return axiosWrapper({ method: 'GET', url: `/catalogs/${catalog}/google-analytics?param1=ga:avgPageLoadTime,ga:pageviews&param2=ga:pagePath&param3=ga:avgPageLoadTime&param4=${dateRange.startDate},${dateRange.endDate}&param5=50` })
};

// response.data
const fetchResourceTree = (catalog: string) => {
  return axiosWrapper({
    method: 'GET',
    url: `/catalogs/${catalog}/document-categories/tree`
  });
};

// response.data
const fetchResources = (catalog: string) => {
  return axiosWrapper({
    method: 'GET',
    url: `/catalogs/${catalog}/document-categories`
  });
};

// response.data
const fetchResource = (catalog: string, resourceId: number| string) => {
  return axiosWrapper({
    method: 'GET',
    url: `/catalogs/${catalog}/document-categories/${resourceId}`
  });
};

// empty response
const updateResource = (catalog: string, resourceId: number, values: BaseResource) => axiosWrapper({
  method: 'PATCH',
  url: `v2/catalogs/${catalog}/document-categories/${resourceId}`,
  data: { ...values }
});

// empty response
const createResource = (catalog: string, values: BaseResource) => axiosWrapper({
  method: 'POST',
  url: `v2/catalogs/${catalog}/document-categories`,
  data: { ...values }
});

// empty response
const deleteResource = (catalog: string, resourceId: number) => axiosWrapper({
  method: 'DELETE',
  url: `/catalogs/${catalog}/document-categories/${resourceId}`
});

const reorderResources = (catalog: string, resourceStructure: SequencedCategory[]) => axiosWrapper({
  method: 'PATCH',
  url: `/catalogs/${catalog}/document-categories`,
  data: [ ...resourceStructure ]
});

const syncAssets = () => {
  return axiosWrapper({method: 'GET', url: `/assets/_sync`})
}

// response.data
const fetchAssets = (catalog: string, pageOptions: PaginatedAssetsReqParams) => {
  const optionsString = querystring.stringify(pageOptions as Record<string, any>);
  return axiosWrapper({ method: 'GET', url: `/v3/catalogs/${catalog}/assets?${optionsString}` })
  // return axiosWrapper({ method: 'GET', url: `/v2/catalogs/${catalog}/assets?fileType=document&pageSize=100&prefix=ah&continuationToken=` })
  // return Promise.resolve({"data":{"continuationToken":"","assets":[]},"error":null}) as Promise<any>
  // return Promise.reject({"data": 'API error'}) as Promise<any>
};

const exportAssets = (catalog: string) => axiosWrapper({ method: 'GET', url: `v2/catalogs/${catalog}/assets/_export`, responseType: 'blob'});

const fetchAsset = (catalog: string, assetId: number | string) => {
  return axiosWrapper({
    method: 'GET',
    url: `v2/catalogs/${catalog}/assets/${assetId}`
  })
  // return Promise.resolve({data: {
  //   altText: "metal-flange",
  //     assetLinkedCategories: [],
  //     assetLinkedProducts: [{id: 3109, name: "test attr issue"}, {id: 4014, name: "new test image"}],
  //     assetLinkedResources: [{id: 12, name: "test"}],
  //     assetTags: [],
  //     description: "some kind of metal flange",
  //     id: 2227,
  //     name: "003347.jpg",
  //     path: "images/003347.jpg"
  // }})
}

// response (containing asset name and path)
const uploadAsset = (asset: File, onProgress: Function) => {
  const formData = new FormData();
  const formattedName = `${asset.name}`;
  // 3rd param filename needed for requests in Edge
  // otherwise user's entire local filepath is POSTed
  formData.append('file', asset, formattedName);
  //@ts-ignore
  formData.append('data', '{}');
  return axiosWrapper({
    method: 'POST', url: `/v2/assets`, data: formData, headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: ({ total, loaded }: { total: number, loaded: number }) => {
      onProgress({ percent: Math.round(loaded / total * 100) }, asset);
    }
  });
};

const uploadRemoteAsset = (values: any) => {
  return axiosWrapper({
    method: 'POST',
    url: '/assets/remote',
    data: {...values}
  })
}

const updateAsset = (assetId: number, values: LibraryAsset) => {
  return axiosWrapper({
    method: 'PATCH',
    url: `v2/assets/${assetId}`,
    data: {...values}
  })
};

// empty response
const deleteAsset = (catalog: string, assetId: number | string) => {
  return axiosWrapper({
    method: 'DELETE',
    url: `/v3/catalogs/${catalog}/assets/${assetId}`
  });
}

const fetchAssetLabels = (searchTerm?: string) => {
  const queryParam = querystring.stringify({q: searchTerm})
  return axiosWrapper({
    method: 'GET',
    url: `/assets/assetTags?${queryParam}`
  })
}

const fetchOverlays = (catalog: string) => axiosWrapper({
  method: 'GET',
  url: `catalogs/${catalog}/imageoverlaytypes`
});

const fetchOverlay = (catalog: string, overlayId: number | string) => axiosWrapper({
  method: 'GET', url: `catalogs/${catalog}/imageoverlaytypes/${overlayId}`
});

const updateOverlay = (catalog: string, overlayId: number, values: ImageOverlayToApi) => axiosWrapper({
  method: 'PATCH',
  url: `catalogs/${catalog}/imageoverlaytypes/${overlayId}`,
  data: { ...values }
})

const createOverlay = (catalog: string, values: ImageOverlayToApi) => axiosWrapper({
  method: 'POST',
  url: `catalogs/${catalog}/imageoverlaytypes`,
  data: { ...values }
})

const deleteOverlay = (catalog: string, overlayId: number | string) => axiosWrapper({
  method: 'DELETE',
  url: `catalogs/${catalog}/imageoverlaytypes/${overlayId}`
});

const fetchCountries = (catalog: string) => axiosWrapper({
  method: 'GET',
  url: `/catalogs/${catalog}/countries`
});

const fetchCountry = (catalog: string, countryId: number | string) => axiosWrapper({
  method: 'GET',
  url: `/catalogs/${catalog}/countries/${countryId}`
});

const updateCountry = (catalog: string, countryId: number, values: Country) => axiosWrapper({
  method: 'PUT',
  url: `/catalogs/${catalog}/countries/${countryId}`,
  data: { ...values }
});

const createCountry = (catalog: string, values: Country) => axiosWrapper({
  method: 'POST',
  url: `/catalogs/${catalog}/countries`,
  data: { ...values }
});

const deleteCountry = (catalog: string, countryId: number | string) => axiosWrapper({
  method: 'DELETE',
  url: `/catalogs/${catalog}/countries/${countryId}`
});

const fetchStates = (catalog: string) => axiosWrapper({
  method: 'GET',
  url: `/catalogs/${catalog}/states`
});

const fetchState = (catalog: string, stateId: number | string) => axiosWrapper({
  method: 'GET',
  url: `/catalogs/${catalog}/states/${stateId}`
});

const updateState = (catalog: string, stateId: number, values: State) => axiosWrapper({
  method: 'PUT',
  url: `/catalogs/${catalog}/states/${stateId}`,
  data: { ...values }
});

const createState = (catalog: string, values: State) => axiosWrapper({
  method: 'POST',
  url: `/catalogs/${catalog}/states`,
  data: { ...values }
});

const deleteState = (catalog: string, stateId: number | string) => axiosWrapper({
  method: 'DELETE',
  url: `/catalogs/${catalog}/states/${stateId}`
});

const fetchCustomFields = (catalog: string) => axiosWrapper({
  method: 'GET',
  url: `/catalogs/${catalog}/customfields`
});


const createCustomField = (catalog: string, values: CustomField) => {
  return axiosWrapper({
    method: 'POST',
    url: `/catalogs/${catalog}/customfields`,
    data: {...values}
  })};
//   console.log(values)
//   return Promise.resolve({"data": ""})
// }

const updateCustomFieldsList = (catalog: string, tabsList: { tabs: string[] }) => {
  return axiosWrapper({
    method: 'PATCH',
    url: `/catalogs/${catalog}/customfields/_sorttabs`,
    data: tabsList
  })
  // return Promise.resolve(tabsList)
}

const deleteCustomField = (catalog: string, fieldName: string) => axiosWrapper({
  method: 'DELETE',
    url: `/catalogs/${catalog}/customfields/${fieldName}`
});

const fetchBooleanWebControls = (catalog: string) => axiosWrapper({
  method: 'GET',
  url: `/catalogs/${catalog}/GetBooleanWebControls`
});

const fetchIntegerWebControls = (catalog: string) => axiosWrapper({
  method: 'GET',
  url: `/catalogs/${catalog}/GetIntegerWebControls`
});

const fetchStringWebControls = (catalog: string) => axiosWrapper({
  method: 'GET',
  url: `/catalogs/${catalog}/GetStringWebControls`
});

const fetchTileWebControls = (catalog: string) => axiosWrapper({
  method: 'GET',
  url: `/catalogs/${catalog}/GetTileWebControls`
});

const fetchSiteWebControls = (catalog: string) => axiosWrapper({
  method: 'GET',
  url: `/catalogs/${catalog}/GetSiteWebControls`
});

const fetchOtherWebControls = (catalog: string) => axiosWrapper({
  method: 'GET',
  url: `/catalogs/${catalog}/GetOtherWebControls`
});

const updateWebControl = (catalog: string, values: WebControl) => axiosWrapper({
  method: 'POST',
  url: `/catalogs/${catalog}/UpdateWebControl`,
  data: { ...values }
});

const fetchSalesReps = () => axiosWrapper({
  method: 'GET',
  url: `/reps`
})

const fetchSalesRep = (repId: number| string) => axiosWrapper({
  method: 'GET',
  url: `/reps/${repId}`
})

const createSalesRep = (values: SalesRepToApi) => axiosWrapper({
  method: 'POST',
  url: `/reps`,
  data: { ...values }
})

const updateSalesRep = (repId: number, values: SalesRepToApi) => axiosWrapper({
  method: 'PUT',
  url: `/reps/${repId}`,
  data: { ...values }
})

const deleteSalesRep = (repId: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `/reps/${repId}` });
};

const fetchSalesRepTypes = () => axiosWrapper({
  method: 'GET',
  url: `/reptypes`
})

const fetchSalesRepType = (repTypeId: number| string) => axiosWrapper({
  method: 'GET',
  url: `/reptypes/${repTypeId}`
})

const createSalesRepType = (values: SalesRepType) => axiosWrapper({
  method: 'POST',
  url: `/repTypes`,
  data: { ...values }
})

const updateSalesRepType = (repId: number, values: SalesRepType) => axiosWrapper({
  method: 'PUT',
  url: `/repTypes/${repId}`,
  data: { ...values }
})

const deleteSalesRepType = (repId: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `/RepTypes/${repId}` });
};

const fetchFeatureFlags = (catalogName: string) => {
  return axiosWrapper({
    method: 'GET',
    url: `v2/catalogs/${catalogName}/features`
  })
};

const fetchFeatureFlag = (catalogName: string, flagName: string) => {
  return axiosWrapper({
    method: 'GET',
    url: `v2/catalogs/${catalogName}/feature/${flagName}`
  })
}

const updateFeatureFlag = (catalogName: string, featureName: string, values: FeatureFlagToApi) => {
  return axiosWrapper({
    method: 'PATCH',
    url: `v2/catalogs/${catalogName}/feature/${featureName}`,
    data: {...values}
  })
}

const fetchCostCenters = () => {
  return axiosWrapper({
    method: 'GET',
    url: `/costcenters`
  })
};

const fetchCostCenter = (id: number| string) => {
  return axiosWrapper({
    method: 'GET',
    url: `/costcenters/${id}`
  })
};

const createCostCenter = (values: ExtraBilling) => axiosWrapper({
  method: 'POST',
  url: `/costcenters`,
  data: { ...values }
})

const updateCostCenter = (id: number, values: ExtraBilling) => axiosWrapper({
  method: 'PATCH',
  url: `/costcenters/${id}`,
  data: { ...values }
})

const deleteCostCenter = (id: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `/costcenters/${id}` });
};

const fetchApprovalGroups = () => {
  return axiosWrapper({ method: 'GET', url: `/approvalManagers` });
}

const fetchApprovalGroup = (groupId: number | string) => {
  return axiosWrapper({ method: 'GET', url: `/approvalManagers/${groupId}` });
}

// returns a list of group names.  Used to autosuggest existing names
// for selection on InnerApprovalGroupForm
const fetchApprovalGroupNames = () => {
  return axiosWrapper({ method: 'GET', url: "/approvalManagers/approvalGroupList" });
}

const createApprovalGroup = (values: ApprovalGroup) => {
  return axiosWrapper({ method: 'POST', url: `/approvalManagers`, data: { ...values } });
}

const updateApprovalGroup = (groupId: number, values: ApprovalGroup) => {
  return axiosWrapper({ method: 'PATCH', url: `/approvalManagers/${groupId}`, data: { ...values } });
}

const deleteApprovalGroup = (groupId: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `/approvalManagers/${groupId}` });
}

const fetchLoginBudgets = () => {
  return axiosWrapper({ method: 'GET', url: `/LoginBudget` });
}

const fetchLoginBudget = (budgetId: number | string) => {
  return axiosWrapper({ method: 'GET', url: `/LoginBudget/${budgetId}` });
}

const createLoginBudget = (values: LoginBudgetToApi) => {
  return axiosWrapper({ method: 'POST', url: `/LoginBudget`, data: { ...values } });
}

const updateLoginBudget = (budgetId: number | string, values: LoginBudgetToApi) => {
  return axiosWrapper({ method: 'PUT', url: `/LoginBudget/${budgetId}`, data: { ...values } });
}

const deleteLoginBudget = (budgetId: number | string) => {
  return axiosWrapper({ method: 'DELETE', url: `/LoginBudget/${budgetId}` });
}

const fetchCompanyInfos = (catalog: string) => {
  return axiosWrapper({ method: 'GET', url: `catalogs/${catalog}/CompanyInfos` });
}

const fetchCompanyInfo = (catalog: string, companyName: string) => {
  return axiosWrapper({ method: 'GET', url: `catalogs/${catalog}/CompanyInfo/${companyName}` });
}

const updateCompanyInfo = (catalog: string, companyName: string, values: CompanyInfoToAPI) => {
  return axiosWrapper({ method: 'PATCH', url: `catalogs/${catalog}/CompanyInfo/${companyName}`, data: {...values} });
}

const fetchAzureUsers = () => {
  return axiosWrapper({ method: 'GET', url: `/v1/azure/users` });
}

const fetchAzureUser = (email: string) => {
  return axiosWrapper({ method: 'GET', url: `/v1/azure/users/${email}` });
}

const createAzureUser = (values: AzureUser) => {
  return axiosWrapper({ method: 'POST', url: `/v1/azure/users`, data: { ...values } });
}

const deleteAzureUser = (email: string) => {
  return axiosWrapper({ method: 'DELETE', url: `/v1/azure/users/${email}` });
}

export {
  url,
  fetchCatalogs,
  fetchCatalog,
  fetchCategories,
  fetchCategory,
  fetchCategoryTree,
  deleteCategory,
  updateCategory,
  createCategory,
  reorderCategories,
  alphabetizeCategories,
  exportCategories,
  propagateCatImages,
  createProduct,
  deleteProduct,
  deleteProducts,
  exportProducts,
  exportRelatedProducts,
  exportVariantProducts,
  exportGalleryImages,
  fetchProduct,
  checkPartNumberUnique,
  checkFriendlyUrlUnique,
  fetchPagedProducts,
  updateProduct,
  fetchBrands,
  fetchBrand,
  updateBrand,
  createBrand,
  deleteBrand,
  fetchProductBackups,
  fetchProductBackup,
  createProductBackup,
  restoreProductBackup,
  deleteProductBackup,
  fetchWarehouses,
  fetchWarehouse,
  createWarehouse,
  updateWarehouse,
  deleteWarehouse,
  reorderWarehouses,
  uploadSpreadsheet,
  mapProductFieldsFromUpload,
  mapCategoryFieldsFromUpload,
  mapCustomerFieldsFromUpload,
  mapTranslationFieldsFromUpload,
  mapBillingFieldsFromUpload,
  mapProductDocFieldsFromUpload,
  mapProductGalleryFieldsFromUpload,
  deleteBulkUpload,
  fetchCoupons,
  fetchCoupon,
  createProductCoupon,
  createOrderCoupon,
  deleteCoupon,
  updateProductCoupon,
  updateOrderCoupon,
  fetchSearchConfig,
  updateSearchConfig,
  indexSearch,
  productSearch,
  fetchCustomers,
  fetchCustomer,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  fetchLogins,
  fetchLogin,
  createLogin,
  updateLogin,
  deleteLogin,
  resetLoginPW,
  fetchShippingMethods,
  fetchShippingMethod,
  createShippingMethod,
  updateShippingMethod,
  deleteShippingMethod,
  fetchCatalogTerms,
  fetchTerm,
  createTerm,
  updateTerm,
  deleteTerm,
  fetchTranslations,
  fetchTranslation,
  createTranslation,
  updateTranslation,
  deleteTranslation,
  fetchShippingRates,
  fetchShippingRate,
  createShippingRate,
  updateShippingRate,
  deleteShippingRate,
  fetchShipTos,
  fetchShipTo,
  createShipTo,
  updateShipTo,
  deleteShipTo,
  fetchCustomerNums,
  fetchCustNumsByCustId,
  fetchCustomerNum,
  updateCustomerNum,
  createCustomerNum,
  deleteCustomerNum,
  fetchContentGroups,
  fetchContentGroup,
  createContentGroup,
  updateContentGroup,
  deleteContentGroup,
  getDashboardCardAnalytics,
  getTopRevenue,
  getOrderHistory,
  getAbandonedCarts,
  getLoginReports,
  getFailedSearches,
  getSuccessfulSearches,
  getUsageReports,
  getACHReports,
  getPaginatedACHReports,
  updateACHReportStatus,
  exportPaginatedACHReports,
  getAnalyticsConfig,
  updateAnalyticsConfig,
  deleteAnalyticsConfig,
  fetchAudienceOverviewData,
  fetchGASessionInfoCountry,
  fetchGASessionInfoBrowser,
  fetchGASessionInfoDevice,
  fetchGAWebsiteTrafficData,
  fetchGATopRevenueProducts,
  fetchEcommerceOverviewData,
  fetchProductPerformanceData,
  fetchAvgPageLoadData,
  tusImageUpload,
  tusDocumentUpload,
  fetchResourceTree,
  fetchResources,
  fetchResource,
  updateResource,
  createResource,
  deleteResource,
  reorderResources,
  syncAssets,
  fetchAssets,
  exportAssets,
  fetchAsset,
  uploadAsset,
  uploadRemoteAsset,
  updateAsset,
  fetchAssetLabels,
  deleteAsset,
  fetchOverlays,
  fetchOverlay,
  updateOverlay,
  createOverlay,
  deleteOverlay,
  fetchCountries,
  fetchCountry,
  updateCountry,
  createCountry,
  deleteCountry,
  fetchStates,
  fetchState,
  updateState,
  createState,
  deleteState,
  fetchCustomFields,
  createCustomField,
  updateCustomFieldsList,
  deleteCustomField,
  fetchBooleanWebControls,
  fetchIntegerWebControls,
  fetchStringWebControls,
  fetchTileWebControls,
  fetchSiteWebControls,
  fetchOtherWebControls,
  updateWebControl,
  fetchSalesReps,
  fetchSalesRep,
  updateSalesRep,
  createSalesRep,
  deleteSalesRep,
  fetchSalesRepTypes,
  fetchSalesRepType,
  createSalesRepType,
  updateSalesRepType,
  deleteSalesRepType,
  fetchFeatureFlags,
  fetchFeatureFlag,
  updateFeatureFlag,
  fetchCostCenters,
  fetchCostCenter,
  createCostCenter,
  updateCostCenter,
  deleteCostCenter,
  fetchApprovalGroups,
  fetchApprovalGroup,
  fetchApprovalGroupNames,
  createApprovalGroup,
  updateApprovalGroup,
  deleteApprovalGroup,
  fetchLoginBudgets,
  fetchLoginBudget,
  createLoginBudget,
  updateLoginBudget,
  deleteLoginBudget,
  fetchCompanyInfos,
  fetchCompanyInfo,
  updateCompanyInfo,
  fetchAzureUsers,
  fetchAzureUser,
  createAzureUser,
  deleteAzureUser
};
