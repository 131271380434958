import { lazy } from 'react';

const Redirect = lazy(() => import('./components/Redirect'))

const Dashboard = lazy(() => import('./views/Dashboard/Dashboard'))

const DashboardHelp = lazy(() => import('./views/Dashboard/DashboardHelp'))

const SelectCatalog = lazy(() => import('./views/Pages/SelectCatalog/SelectCatalog'))

const Categories = lazy(() => import('./views/Categories/CategoriesView'))

const CategoriesHelp = lazy(() => import('./views/Categories/Components/CategoriesViewHelp'))

const ReorderCategory = lazy(() => import('./views/Categories/reorder/ReorderTreeDataView'))

const CategoryReorderHelp = lazy(() => import('./views/Categories/reorder/CategoryReorderHelp'))

const UpdateCategory = lazy(() => import('./views/Categories/update/UpdateCategoryView'))

const CreateCategory = lazy(() => import('./views/Categories/create/CreateCategoryView'))

const CategoryFormHelp = lazy(() => import('./views/Categories/Components/CategoryFormHelp'))

const Brands = lazy(() => import('./views/Brands/BrandsView'))

const BrandsHelp = lazy(() => import('./views/Brands/Components/BrandsViewHelp'))

const UpdateBrand = lazy(() => import('./views/Brands/UpdateBrandView').then(componentFile => {
  return { default: componentFile.UpdateBrandWrapper }
}))

const CreateBrand = lazy(() => import('./views/Brands/CreateBrandView'))

const BrandFormHelp = lazy(() => import('./views/Brands/Components/BrandFormHelp'))

const AssetManagementFormHelp = lazy(() => import('./views/AssetManagement/AssetManagementFormHelp'))

const AssetManagement = lazy(() => import('./views/AssetManagement/AssetManagementView'))

const UpdateAsset = lazy(() => import('./views/AssetManagement/UpdateAssetView').then(componentFile => {
  return { default: componentFile.UpdateAssetWrapper }
}))

const AssetManagementViewHelp = lazy(() => import('./views/AssetManagement/AssetManagementViewHelp'))

const UpdateResource = lazy(() => import('./views/ResourceCenter/UpdateResourceView'))

const CreateResource = lazy(() => import('./views/ResourceCenter/CreateResourceView'))

const ResourceFormHelp = lazy(() => import('./views/ResourceCenter/Components/ResourceFormHelp'))

const ResourceCenter = lazy(() => import('./views/ResourceCenter/ResourcesView').then(componentFile => {
  return { default: componentFile.ResourcesWrapper}
}))

const ResourceCenterHelp = lazy(() => import('./views/ResourceCenter/ResourcesViewHelp'))

const ReorderResource = lazy(() => import('./views/Categories/reorder/ReorderTreeDataView'))

const ResourceReorderHelp = lazy(() => import('./views/ResourceCenter/reorder/ResourceReorderHelp'))

const SearchManagement = lazy(() => import('./views/Search/UpdateSearchConfig'))

const SearchManagementHelp = lazy(() => import('./views/Search/Components/SearchManagementHelp'))

const Warehouses = lazy(() => import('./views/Warehouses/WarehousesView'))

const WarehousesHelp = lazy(() => import('./views/Warehouses/WarehousesViewHelp'))

const UpdateWarehouse = lazy(() => import('./views/Warehouses/UpdateWarehouseView').then(componentFile => {
  return { default: componentFile.UpdateWarehouseWrapper}
}))

const CreateWarehouse = lazy(() => import('./views/Warehouses/CreateWarehouseView'))

const WarehouseFormHelp = lazy(() => import('./views/Warehouses/WarehouseFormHelp'))

const ReorderWarehouse = lazy(() => import('./views/Warehouses/ReorderFlatDataView'))

const WarehouseReorderHelp = lazy(() => import('./views/Warehouses/ReorderWarehouseHelp'))

const ProductBackups = lazy(() => import('./views/DataBackup/Products/ProductBackupView'))

const ProductBackupHelp = lazy(() => import('./views/DataBackup/Products/ProductBackupHelp'))

const RestoreProductBackup = lazy(() => import('./views/DataBackup/Products/RestoreProductBackupView'))

const CreateProductBackup = lazy(() => import('./views/DataBackup/Products/CreateProductBackupView'))

const RestoreProductBackupHelp = lazy(() => import('./views/DataBackup/Products/RestoreProductBackupHelp'))

const CreateProductBackupHelp = lazy(() => import('./views/DataBackup/Products/CreateProductBackupHelp'))

const Import = lazy(() => import('./views/Import/ImportStartWrapper'))

const ImportHelp = lazy(() => import('./views/Import/ImportHelp'))

const Products = lazy(() => import('./views/Products/ProductsView'))

const ProductsHelp = lazy(() => import('./views/Products/ProductsViewHelp'))

const UpdateProduct = lazy(() => import('./views/Products/update/UpdateProductView').then(componentFile => {
  return { default: componentFile.UpdateProductWrapper}
}))

const CreateNewProduct = lazy(() => import('./views/Products/create/CreateProductView'))

const ProductHelp = lazy(() => import('./views/Products/Components/ProductFormHelp'))

const Coupons = lazy(() => import('./views/Coupons/CouponsView').then(componentFile => {
  return { default: componentFile.CouponsWrapper}
}))

const CouponsHelp = lazy(() => import('./views/Coupons/CouponsViewHelp'))

const UpdateCoupon = lazy(() => import('./views/Coupons/update/UpdateCouponView').then(componentFile => {
  return { default: componentFile.UpdateCouponWrapper}
}))

const CreateNewCoupon = lazy(() => import('./views/Coupons/create/CreateCouponView'))

const CouponHelp = lazy(() => import('./views/Coupons/Components/CouponFormHelp'))

const UpdateCustomer = lazy(() => import('./views/Customers/UpdateCustomerView').then(componentFile => {
  return { default: componentFile.UpdateCustomerWrapper}
}))

const CreateNewCustomer = lazy(() => import('./views/Customers/CreateCustomerView'))

const CustomerHelp = lazy(() => import('./views/Customers/CustomerFormHelp'))

const Customers = lazy(() => import('./views/Customers/CustomersView'))

const CustomersHelp = lazy(() => import('./views/Customers/CustomersViewHelp'))

const UpdateLogin = lazy(() => import('./views/Logins/UpdateLoginView').then(componentFile => {
  return { default: componentFile.UpdateLoginWrapper}
}))

const CreateNewLogin = lazy(() => import('./views/Logins/CreateLoginView'))

const LoginHelp = lazy(() => import('./views/Logins/LoginFormHelp'))

const Logins = lazy(() => import('./views/Logins/LoginsView'))

const LoginsHelp = lazy(() => import('./views/Logins/LoginsViewHelp'))

const UpdateShippingMethod = lazy(() => import('./views/ShippingMethods/UpdateShipMethodView').then(componentFile => {
  return { default: componentFile.UpdateShipMethodWrapper}
}))

const CreateNewShippingMethod = lazy(() => import('./views/ShippingMethods/CreateShipMethodView'))

const ShippingMethodHelp = lazy(() => import('./views/ShippingMethods/ShippingFormHelp'))

const ShippingMethods = lazy(() => import('./views/ShippingMethods/ShippingMethodsView'))

const ShippingMethodsHelp = lazy(() => import('./views/ShippingMethods/ShippingMethodsViewHelp'))

const UpdateShippingRate = lazy(() => import('./views/ShippingRates/UpdateShipRateView').then(componentFile => {
  return { default: componentFile.UpdateShipRateWrapper}
}))

const CreateNewShippingRate = lazy(() => import('./views/ShippingRates/CreateShipRateView'))

const ShippingRateHelp = lazy(() => import('./views/ShippingRates/ShippingRateHelp'))

const ShippingRates = lazy(() => import('./views/ShippingRates/ShippingRatesView').then(componentFile => {
  return { default: componentFile.ShippingRatesWrapper}
}))

const ShippingRatesHelp = lazy(() => import('./views/ShippingRates/ShippingRatesViewHelp'))

const UpdateTerm = lazy(() => import('./views/Terms/UpdateTermView').then(componentFile => {
  return { default: componentFile.UpdateTermWrapper}
}))

const CreateNewTerm = lazy(() => import('./views/Terms/CreateTermView'))

const TermHelp = lazy(() => import('./views/Terms/TermFormHelp'))

const Terms = lazy(() => import('./views/Terms/TermsView'))

const TermsHelp = lazy(() => import('./views/Terms/TermsViewHelp'))

const Translations = lazy(() => import('./views/Translations/TranslationsView'))

const TranslationsHelp = lazy(() => import('./views/Translations/TranslationsViewHelp'))

const UpdateTranslation = lazy(() => import('./views/Translations/UpdateTranslationView').then(componentFile => {
  return { default: componentFile.UpdateTranslationWrapper }
  })
)

const CreateNewTranslation = lazy(() => import('./views/Translations/CreateTranslationView'))

const TranslationHelp = lazy(() => import('./views/Translations/TranslationFormHelp'))

const CustomerNumbers = lazy(() => import('./views/CustomerNumbers/CustomerNumbersView'))

const CustNumsHelp = lazy(() => import('./views/CustomerNumbers/CustNumsViewHelp'))

const UpdateCustomerNumber = lazy(() => import('./views/CustomerNumbers/UpdateCustNumView').then(componentFile => {
  return { default: componentFile.UpdateCustNumWrapper}
}))

const CreateCustomerNumber = lazy(() => import('./views/CustomerNumbers/CreateCustNumView'))

const CustNumHelp = lazy(() => import('./views/CustomerNumbers/CustNumFormHelp'))

const ContentGroups = lazy(() => import('./views/ContentGroups/ContentGroupsView').then(componentFile => {
  return { default: componentFile.ContentGroupsWrapper}
}))

const UpdateContentGroup = lazy(() => import('./views/ContentGroups/UpdateContentGroupView').then(componentFile => {
  return { default: componentFile.UpdateContentGroupWrapper}
}))

const CreateContentGroup = lazy(() => import('./views/ContentGroups/CreateContentGroupView'))

const ContentGroupHelp = lazy(() => import('./views/ContentGroups/ContentGroupFormHelp'))

const ContentGroupsViewHelp = lazy(() => import('./views/ContentGroups/ContentGroupViewHelp'))

const OrderHistory = lazy(() => import('./views/Analytics/components/OrderHistory').then(componentFile => {
  return { default: componentFile.OrderHistoryWrapper}
}))

const OrderHistoryHelp = lazy(() => import('./views/Analytics/components/OrderHistoryHelp'))

const AbandonedCartReports = lazy(() => import('./views/Analytics/components/AbandonedCartReports'))

const AbandonedCartHelp = lazy(() => import('./views/Analytics/components/AbandonedCartHelp'))

const LoginReports = lazy(() => import('./views/Analytics/components/LoginReports'))

const LoginReportsHelp = lazy(() => import('./views/Analytics/components/LoginReportsHelp'))

const SuccessfulSearches = lazy(() => import('./views/Analytics/components/SuccessfulSearches'))

const SuccessfulSearchesHelp = lazy(() => import('./views/Analytics/components/SuccessfulSearchesHelp'))

const FailedSearches = lazy(() => import('./views/Analytics/components/FailedSearches'))

const UsageReports = lazy(() => import('./views/Analytics/components/UsageReports'))

const UsageReportsHelp = lazy(() => import('./views/Analytics/components/UsageReportsHelp'))

const ACHReports = lazy(() => import('./views/Analytics/components/PaginatedACHReports').then(componentFile => {
  return { default: componentFile.ACHReportsWrapper}
}))

const ACHReportsHelp = lazy(() => import('./views/Analytics/components/ACHReportsHelp'))

const AudienceOverview = lazy(() => import('./views/Google/AudienceOverview'))

const AudienceOverviewHelp = lazy(() => import('./views/Google/components/AudienceOverviewHelp'))

const EcommerceOverview = lazy(() => import('./views/Google/EcommerceOverview'))

const EcommerceOverviewHelp = lazy(() => import('./views/Google/components/EcommerceOverviewHelp'))

const GoogleAnalyticsSettings = lazy(() => import('./views/Google/UpdateAnalyticsSettingsView'))

const GoogleAnalyticsSettingsHelp = lazy(() => import('./views/Google/AnalyticsSettingsHelp'))

const WebSetupCountries = lazy(() => import('./views/WebSetup/Countries/CountriesView').then(componentFile => {
  return { default: componentFile.CountriesWrapper}
}))

const WebSetupCountriesHelp = lazy(() => import('./views/WebSetup/Countries/CountriesViewHelp'))

const UpdateWebSetupCountry = lazy(() => import('./views/WebSetup/Countries/UpdateCountryView').then(componentFile => {
  return { default: componentFile.UpdateCountryWrapper}
}))

const CreateWebSetupCountry = lazy(() => import('./views/WebSetup/Countries/CreateCountryView').then(componentFile => {
  return { default: componentFile.CreateCountryWrapper}
}))

const WebSetupCountryHelp = lazy(() => import('./views/WebSetup/Countries/CountryViewHelp'))

const WebSetupStates = lazy(() => import('./views/WebSetup/States/StatesView').then(componentFile => {
  return { default: componentFile.StatesViewWrapper}
}))

const WebSetupStatesHelp = lazy(() => import('./views/WebSetup/States/StatesViewHelp'))

const UpdateWebSetupState = lazy(() => import('./views/WebSetup/States/UpdateStateView').then(componentFile => {
  return { default: componentFile.UpdateStateWrapper}
}))

const CreateWebSetupState = lazy(() => import('./views/WebSetup/States/CreateStateView').then(componentFile => {
  return { default: componentFile.CreateStateWrapper}
}))

const WebSetupStateHelp = lazy(() => import('./views/WebSetup/States/StateViewHelp'))

const WebSetupCustomFields = lazy(() => import('./views/WebSetup/CustomFields/CustomFieldsView').then(componentFile => {
  return { default: componentFile.CustomFieldsWrapper}
}))

const WebSetupCustomFieldsViewHelp = lazy(() => import('./views/WebSetup/CustomFields/CustomFieldsViewHelp'))

const CreateWebSetupCustomField = lazy(() => import('./views/WebSetup/CustomFields/CreateCustomFieldView').then(componentFile => {
  return { default: componentFile.CreateCustomFieldWrapper}
}))

const UpdateWebSetupCustomFields = lazy(() => import('./views/WebSetup/CustomFields/ReorderCustomFieldsView').then(componentFile => {
  return { default: componentFile.ReorderCustomFieldsWrapper}
}))

const WebSetupCustomFieldViewHelp = lazy(() => import('./views/WebSetup/CustomFields/CustomFieldViewHelp'))

const WebSetupReorderCustomFieldHelp = lazy(() => import('./views/WebSetup/CustomFields/ReorderCustomFieldHelp'))

const WebSetupWebControls = lazy(() => import('./views/WebSetup/WebControls/WebControlsView').then(componentFile => {
  return { default: componentFile.WebControlsWrapper}
}))

const WebSetupWebControlsHelp = lazy(() => import('./views/WebSetup/WebControls/WebControlsHelp'))

const UpdateWebControl = lazy(() => import('./views/WebSetup/WebControls/UpdateWebControlView').then(componentFile => {
  return { default: componentFile.UpdateWebControlWrapper}
}))

const WebSetupWebControlHelp = lazy(() => import('./views/WebSetup/WebControls/WebControlHelp'))

const SalesReps = lazy(() => import('./views/SalesReps/Reps/SalesRepsView').then(componentFile => {
  return { default: componentFile.SalesRepsWrapper}
}))

const SalesRepsHelp = lazy(() => import('./views/SalesReps/Reps/SalesRepsViewHelp'))

const UpdateSalesRep = lazy(() => import('./views/SalesReps/Reps/UpdateSalesRepView').then(componentFile => {
  return { default: componentFile.UpdateSalesRepWrapper}
}))

const CreateSalesRep = lazy(() => import('./views/SalesReps/Reps/CreateSalesRepView'))

const SalesRepHelp = lazy(() => import('./views/SalesReps/Reps/SalesRepFormHelp'))

const SalesRepTypes = lazy(() => import('./views/SalesReps/RepTypes/RepTypesView').then(componentFile => {
  return { default: componentFile.SalesRepTypesWrapper}
}))

const UpdateRepType = lazy(() => import('./views/SalesReps/RepTypes/UpdateRepTypeView').then(componentFile => {
  return { default: componentFile.UpdateRepTypeWrapper}
}))

const CreateRepType = lazy(() => import('./views/SalesReps/RepTypes/CreateRepTypeView'))

const FeatureFlagsView = lazy(() => import('./views/FeatureFlags/FeatureFlagsView').then(componentFile => {
  return { default: componentFile.FeatureFlagsWrapper }
}))

const UpdateFeatureFlagView = lazy(() => import('./views/FeatureFlags/UpdateFeatureFlagView').then(componentFile => {
  return { default: componentFile.UpdateFeatureFlagWrapper}
}))

const FeatureFlagsViewHelp = lazy(() => import('./views/FeatureFlags/FlagHelpCards/FeatureFlagsViewHelp'))

const FeatureFlagHelp = lazy(() => import('./views/FeatureFlags/FlagHelpCards/FeatureFlagHelp'))

const ShippingAddressesView = lazy(() => import('./views/ShippingAddresses/ShippingAddressesView').then(componentFile => {
  return { default: componentFile.ShippingAddressesWrapper}
}))

const ShippingAddressesHelp = lazy(() => import('./views/ShippingAddresses/ShippingAddressesViewHelp'))

const UpdateShippingAddressView = lazy(() => import('./views/ShippingAddresses/UpdateShipAddressView').then(componentFile => {
  return { default: componentFile.UpdateShipAddressWrapper}
}))

const CreateShippingAddressView = lazy(() => import('./views/ShippingAddresses/CreateShipAddressView'))

const ShippingAddressHelp = lazy(() => import('./views/ShippingAddresses/ShipAddressFormHelp'))

const ExtraBillingView = lazy(() => import('./views/ExtraBilling/ExtraBillingsView').then(componentFile => {
  return { default: componentFile.ExtraBillingsWrapper}
}))

const ExtraBillingsHelp = lazy(() => import('./views/ExtraBilling/ExtraBillingsViewHelp'))

const UpdateExtraBillingView = lazy(() => import('./views/ExtraBilling/UpdateExtraBillingView').then(componentFile => {
  return { default: componentFile.UpdateExtraBillingWrapper}
}))

const CreateExtraBillingView = lazy(() => import('./views/ExtraBilling/CreateExtraBillingView'))

const ExtraBillingHelp = lazy(() => import('./views/ExtraBilling/ExtraBillingFormHelp'))

const ApprovalGroupsView = lazy(() => import('./views/ApprovalGroups/ApprovalGroupsView').then(componentFile => {
  return { default: componentFile.ApprovalGroupsWrapper}
}))

const ApprovalGroupsHelp = lazy(() => import('./views/ApprovalGroups/ApprovalGroupsViewHelp'))

const UpdateApprovalGroupView = lazy(() => import('./views/ApprovalGroups/UpdateApprovalGroupView').then(componentFile => {
  return { default: componentFile.UpdateApprovalGroupWrapper}
}))

const CreateApprovalGroupView = lazy(() => import('./views/ApprovalGroups/CreateApprovalGroupView'))

const ApprovalGroupHelp = lazy(() => import('./views/ApprovalGroups/ApprovalGroupFormHelp'))

const LoginBudgets = lazy(() => import('./views/LoginBudgets/LoginBudgetsView').then(componentFile => {
  return { default: componentFile.LoginBudgetsWrapper}
}))

const LoginBudgetsHelp = lazy(() => import('./views/LoginBudgets/LoginBudgetsViewHelp'))

const UpdateLoginBudget = lazy(() => import('./views/LoginBudgets/UpdateLoginBudgetView').then(componentFile => {
  return { default: componentFile.UpdateLoginBudgetWrapper}
}))

const CreateLoginBudget = lazy(() => import('./views/LoginBudgets/CreateLoginBudgetView'))

const LoginBudgetHelp = lazy(() => import('./views/LoginBudgets/LoginBudgetViewHelp'))

const ImageOverlays = lazy(() => import('./views/ImageOverlays/ImageOverlaysView'))

const ImageOverlaysHelp = lazy(() => import('./views/ImageOverlays/OverlaysViewHelp'))

const UpdateImageOverlay = lazy(() => import('./views/ImageOverlays/UpdateOverlayView').then(componentFile => {
  return { default: componentFile.UpdateOverlayWrapper}
}))

const CreateNewImageOverlay = lazy(() => import('./views/ImageOverlays/CreateOverlayView'))

const ImageOverlayHelp = lazy(() => import('./views/ImageOverlays/OverlayFormHelp'))

const UpdateCompanyInfo = lazy(() => import('./views/CompanyInfo/UpdateCompanyInfoView').then(componentFile => {
  return { default: componentFile.UpdateCompanyInfoWrapper}
}))

const CompanyInfoHelp = lazy(() => import('./views/CompanyInfo/CompanyInfoFormHelp'))

const AzureUsersView= lazy(() => import('./views/UserManagement/AzureUsersView').then(componentFile => {
  return { default: componentFile.AzureUsersWrapper}
}))

const AzureUsersViewHelp= lazy(() => import('./views/UserManagement/AzureUsersViewHelp'))

const CreateAzureUserView= lazy(() => import('./views/UserManagement/CreateAzureUserView').then(componentFile => {
  return { default: componentFile.CreateAzureUserWrapper}
}))

const UpdateAzureUserView = lazy(() => import('./views/UserManagement/UpdateAzureUserView').then(componentFile => {
  return { default: componentFile.UpdateAzureUserWrapper}
}))

const AzureUserFormHelp= lazy(() => import('./views/UserManagement/AzureUserFormHelp'))

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, helpCard: DashboardHelp },
  // { path: '/custom-dashboard', name: 'Custom Dashboard', component: CustomDashboard, helpCard: CustomDashboardHelp },
  { path: '/select-catalog', name: 'SelectCatalog', component: SelectCatalog },
  { path: '/import', name: 'Import Data', component: Import, helpCard: ImportHelp },
  { path: '/product-maintenance/product-backups/restore/:id', name: 'Restore Product Backup', component: RestoreProductBackup, helpCard: RestoreProductBackupHelp},
  { path: '/product-maintenance/product-backups/create', name: 'Create Product Backup', component: CreateProductBackup, helpCard: CreateProductBackupHelp},
  { path: '/product-maintenance/product-backups', name: 'Product Backups', component: ProductBackups, helpCard: ProductBackupHelp},
  { path: '/product-maintenance/products/update/:id', name: 'Update Product', component: UpdateProduct, helpCard: ProductHelp },
  { path: '/product-maintenance/products/create', name: 'Create Product', component: CreateNewProduct, helpCard: ProductHelp },
  { path: '/product-maintenance/products', name: 'Products', component: Products, helpCard: ProductsHelp},
  { path: '/product-maintenance/coupons/update/:id', name: 'Update Coupon', component: UpdateCoupon, helpCard: CouponHelp },
  { path: '/product-maintenance/coupons/create', name: 'Create Coupon', component: CreateNewCoupon, helpCard: CouponHelp },
  { path: '/product-maintenance/coupons', name: 'Coupons', component: Coupons, helpCard: CouponsHelp },
  { path: '/product-maintenance/categories/reorder', name: 'Reorder Categories', component: ReorderCategory, helpCard: CategoryReorderHelp },
  { path: '/product-maintenance/categories/update/:id', name: 'Update Category', component: UpdateCategory, helpCard: CategoryFormHelp },
  { path: '/product-maintenance/categories/create', name: 'Create Category', component: CreateCategory, helpCard: CategoryFormHelp  },
  { path: '/product-maintenance/categories', name: 'Categories', component: Categories,  helpCard: CategoriesHelp },
  { path: '/product-maintenance/brands/update/:id', name: 'Update Brand', component: UpdateBrand, helpCard: BrandFormHelp },
  { path: '/product-maintenance/brands/create', name: 'Create Brand', component: CreateBrand, helpCard: BrandFormHelp  },
  { path: '/product-maintenance/brands', name: 'Brands', component: Brands,  helpCard: BrandsHelp },
  { path: '/product-maintenance/search-management', name: 'Search', component: SearchManagement, helpCard: SearchManagementHelp },
  { path: '/resource-maintenance/asset-management/update/:id', name: 'Update Asset', component: UpdateAsset,  helpCard: AssetManagementFormHelp },
  { path: '/resource-maintenance/asset-management', name: 'Assets', component: AssetManagement,  helpCard: AssetManagementViewHelp },
  { path: '/resource-maintenance/resources/reorder', name: 'Reorder Resources', component: ReorderResource, helpCard: ResourceReorderHelp },
  { path: '/resource-maintenance/resources/update/:id', name: 'Update Resource', component: UpdateResource,  helpCard: ResourceFormHelp },
  { path: '/resource-maintenance/resources/create', name: 'Create Resource', component: CreateResource,  helpCard: ResourceFormHelp },
  { path: '/resource-maintenance/resources', name: 'Resources', component: ResourceCenter,  helpCard: ResourceCenterHelp },
  { path: '/resource-maintenance/overlays/update/:id', name: 'Update Overlay', component: UpdateImageOverlay,  helpCard: ImageOverlayHelp },
  { path: '/resource-maintenance/overlays/create', name: 'Create Overlay', component: CreateNewImageOverlay,  helpCard: ImageOverlayHelp },
  { path: '/resource-maintenance/overlays', name: 'Image Overlays', component: ImageOverlays,  helpCard: ImageOverlaysHelp },
  { path: '/user-maintenance/customers/update/:id', name: 'Update Customer', component: UpdateCustomer, helpCard: CustomerHelp },
  { path: '/user-maintenance/customers/create', name: 'Create Customer', component: CreateNewCustomer, helpCard: CustomerHelp },
  { path: '/user-maintenance/customers', name: 'Customers', component: Customers, helpCard: CustomersHelp },
  { path: '/user-maintenance/logins/update/:id', name: 'Update Login', component: UpdateLogin, helpCard: LoginHelp },
  { path: '/user-maintenance/logins/create', name: 'Create Login', component: CreateNewLogin, helpCard: LoginHelp },
  { path: '/user-maintenance/logins', name: 'Logins', component: Logins, helpCard: LoginsHelp },
  { path: '/user-maintenance/shipping-methods/update/:id', name: 'Update Shipping Method', component: UpdateShippingMethod, helpCard: ShippingMethodHelp },
  { path: '/user-maintenance/shipping-methods/create', name: 'Create Shipping Method', component: CreateNewShippingMethod, helpCard: ShippingMethodHelp },
  { path: '/user-maintenance/shipping-methods', name: 'Shipping Methods', component: ShippingMethods, helpCard: ShippingMethodsHelp },
  { path: '/user-maintenance/shipping-rates/update/:id', name: 'Update Shipping Rate', component: UpdateShippingRate, helpCard: ShippingRateHelp },
  { path: '/user-maintenance/shipping-rates/create', name: 'Create Shipping Rate', component: CreateNewShippingRate, helpCard: ShippingRateHelp },
  { path: '/user-maintenance/shipping-rates', name: 'Shipping Rates', component: ShippingRates, helpCard: ShippingRatesHelp },
  { path: '/user-maintenance/terms/update/:id', name: 'Update Term', component: UpdateTerm, helpCard: TermHelp },
  { path: '/user-maintenance/terms/create', name: 'Create Term', component: CreateNewTerm, helpCard: TermHelp },
  { path: '/user-maintenance/terms', name: 'Terms', component: Terms, helpCard: TermsHelp },
  { path: '/user-maintenance/translations/update/:id', name: 'Update Translation', component: UpdateTranslation, helpCard: TranslationHelp },
  { path: '/user-maintenance/translations/create', name: 'Create Translation', component: CreateNewTranslation, helpCard: TranslationHelp },
  { path: '/user-maintenance/translations', name: 'Translations', component: Translations, helpCard: TranslationsHelp },
  { path: '/user-maintenance/customer-numbers/update/:id', name: 'Update Customer Number', component: UpdateCustomerNumber, helpCard: CustNumHelp },
  { path: '/user-maintenance/customer-numbers/create', name: 'Create Customer Number', component: CreateCustomerNumber, helpCard: CustNumHelp },
  { path: '/user-maintenance/customer-numbers', name: 'Customer Numbers', component: CustomerNumbers, helpCard: CustNumsHelp },
  { path: '/user-maintenance/content-groups/update/:id', name: 'Update Content Group', component: UpdateContentGroup, helpCard: ContentGroupHelp },
  { path: '/user-maintenance/content-groups/create', name: 'Create Content Group', component: CreateContentGroup, helpCard: ContentGroupHelp },
  { path: '/user-maintenance/content-groups', name: 'Content Groups', component: ContentGroups, helpCard: ContentGroupsViewHelp },
  { path: '/user-maintenance/warehouses/update/:id', name: 'Update Warehouse', component: UpdateWarehouse,  helpCard: WarehouseFormHelp },
  { path: '/user-maintenance/warehouses/create', name: 'Create Warehouse', component: CreateWarehouse,  helpCard: WarehouseFormHelp },
  { path: '/user-maintenance/warehouses/reorder', name: 'Reorder Warehouses', component: ReorderWarehouse,  helpCard: WarehouseReorderHelp },
  { path: '/user-maintenance/warehouses', name: 'Warehouses', component: Warehouses,  helpCard: WarehousesHelp },
  { path: '/analytics/order-history', name: 'Order History', component: OrderHistory, helpCard: OrderHistoryHelp },
  { path: '/analytics/abandoned-cart-reports', name: 'Abandoned Cart Reports', component: AbandonedCartReports, helpCard: AbandonedCartHelp },
  { path: '/analytics/login-reports', name: 'Login Reports', component: LoginReports, helpCard: LoginReportsHelp },
  { path: '/analytics/successful-searches', name: 'Successful Searches', component: SuccessfulSearches, helpCard: SuccessfulSearchesHelp },
  { path: '/analytics/failed-searches', name: 'Failed Searches', component: FailedSearches, helpCard: SuccessfulSearchesHelp },
  { path: '/analytics/usage-reports', name: 'Usage Reports', component: UsageReports, helpCard: UsageReportsHelp },
  { path: '/analytics/ach-reports', name: 'ACH Reports', component: ACHReports, helpCard: ACHReportsHelp },
  { path: '/google/audience-overview', name: 'Audience Overview', component: AudienceOverview, helpCard: AudienceOverviewHelp },
  { path: '/google/ecommerce-overview', name: 'Ecommerce Overview', component: EcommerceOverview, helpCard: EcommerceOverviewHelp },
  { path: '/google/ga-config', name: 'Google Analytics Settings', component: GoogleAnalyticsSettings, helpCard: GoogleAnalyticsSettingsHelp },
  { path: '/admin/websetup/countries/update/:id', name: 'Update Country', component: UpdateWebSetupCountry, helpCard: WebSetupCountryHelp },
  { path: '/admin/websetup/countries/create', name: 'Create Country', component: CreateWebSetupCountry, helpCard: WebSetupCountryHelp },
  { path: '/admin/websetup/countries', name: 'Countries', component: WebSetupCountries, helpCard: WebSetupCountriesHelp },
  { path: '/admin/websetup/states/update/:id', name: 'Update State', component: UpdateWebSetupState, helpCard: WebSetupStateHelp },
  { path: '/admin/websetup/states/create', name: 'Create State', component: CreateWebSetupState, helpCard: WebSetupStateHelp },
  { path: '/admin/websetup/states', name: 'States', component: WebSetupStates, helpCard: WebSetupStatesHelp },
  { path: '/admin/websetup/custom-fields/create', name: 'Create Custom Field', component: CreateWebSetupCustomField, helpCard: WebSetupCustomFieldViewHelp },
  { path: '/admin/websetup/custom-fields/reorder', name: 'Update Custom Fields', component: UpdateWebSetupCustomFields, helpCard: WebSetupReorderCustomFieldHelp },
  { path: '/admin/websetup/custom-fields', name: 'Custom Fields', component: WebSetupCustomFields, helpCard: WebSetupCustomFieldsViewHelp },
  { path: '/admin/websetup/webcontrols/booleans/update/:key', name: `Update Boolean WebControl`, component: UpdateWebControl, helpCard: WebSetupWebControlHelp },
  { path: '/admin/websetup/webcontrols/booleans', name: `Boolean WebControls`, component: WebSetupWebControls, helpCard: WebSetupWebControlsHelp },
  { path: '/admin/websetup/webcontrols/integers/update/:key', name: `Update Integer WebControl`, component: UpdateWebControl, helpCard: WebSetupWebControlHelp },
  { path: '/admin/websetup/webcontrols/integers', name: `Integer WebControls`, component: WebSetupWebControls, helpCard: WebSetupWebControlsHelp },
  { path: '/admin/websetup/webcontrols/strings/update/:key', name: `Update String WebControl`, component: UpdateWebControl, helpCard: WebSetupWebControlHelp },
  { path: '/admin/websetup/webcontrols/strings', name: `String WebControls`, component: WebSetupWebControls, helpCard: WebSetupWebControlsHelp },
  { path: '/admin/websetup/webcontrols/tiles/update/:key', name: `Update Tile WebControl`, component: UpdateWebControl, helpCard: WebSetupWebControlHelp },
  { path: '/admin/websetup/webcontrols/tiles', name: `Tile WebControls`, component: WebSetupWebControls, helpCard: WebSetupWebControlsHelp },
  { path: '/admin/websetup/webcontrols/sites/update/:key', name: `Update Site WebControl`, component: UpdateWebControl, helpCard: WebSetupWebControlHelp },
  { path: '/admin/websetup/webcontrols/sites', name: `Site WebControls`, component: WebSetupWebControls, helpCard: WebSetupWebControlsHelp },
  { path: '/admin/websetup/webcontrols/other/update/:key', name: `Update Other WebControl`, component: UpdateWebControl, helpCard: WebSetupWebControlHelp },
  { path: '/admin/websetup/webcontrols/other', name: `Other WebControls`, component: WebSetupWebControls, helpCard: WebSetupWebControlsHelp },
  { path: '/user-maintenance/sales-reps/reps/update/:id', name: `Update Sales Rep`, component: UpdateSalesRep, helpCard: SalesRepHelp },
  { path: '/user-maintenance/sales-reps/reps/create', name: `Create Sales Rep`, component: CreateSalesRep, helpCard: SalesRepHelp },
  { path: '/user-maintenance/sales-reps/reps', name: `Sales Reps`, component: SalesReps, helpCard: SalesRepsHelp },
  { path: '/user-maintenance/sales-reps/rep-types/update/:id', name: `Update Rep Type`, component: UpdateRepType, helpCard: SalesRepHelp },
  { path: '/user-maintenance/sales-reps/rep-types/create', name: `Create Rep Type`, component: CreateRepType, helpCard: SalesRepHelp },
  { path: '/user-maintenance/sales-reps/rep-types', name: `Sales Rep Types`, component: SalesRepTypes, helpCard: SalesRepsHelp },
  { path: '/user-maintenance/shipping-addresses/update/:id', name: `Update Shipping Address`, component: UpdateShippingAddressView, helpCard: ShippingAddressHelp },
  { path: '/user-maintenance/shipping-addresses/create', name: `Create Shipping Address`, component: CreateShippingAddressView, helpCard: ShippingAddressHelp },
  { path: '/user-maintenance/shipping-addresses', name: `Shipping Addresses`, component: ShippingAddressesView, helpCard: ShippingAddressesHelp },
  { path: '/user-maintenance/extra-billing/update/:id', name: `Update Extra Billing`, component: UpdateExtraBillingView, helpCard: ExtraBillingHelp },
  { path: '/user-maintenance/extra-billing/create', name: `Create Extra Billing`, component: CreateExtraBillingView, helpCard: ExtraBillingHelp },
  { path: '/user-maintenance/extra-billing', name: `Extra Billing`, component: ExtraBillingView, helpCard: ExtraBillingsHelp },
  { path: '/user-maintenance/approval-groups/groups/update/:id', name: `Update Approval Group`, component: UpdateApprovalGroupView, helpCard: ApprovalGroupHelp },
  { path: '/user-maintenance/approval-groups/groups/create', name: `Create Approval Group`, component: CreateApprovalGroupView, helpCard: ApprovalGroupHelp },
  { path: '/user-maintenance/approval-groups/groups', name: `Approval Groups`, component: ApprovalGroupsView, helpCard: ApprovalGroupsHelp },
  { path: '/user-maintenance/approval-groups/budgets/update/:id', name: `Update Login Budget`, component: UpdateLoginBudget, helpCard: LoginBudgetHelp },
  { path: '/user-maintenance/approval-groups/budgets/create', name: `Create Login Budget`, component: CreateLoginBudget, helpCard: LoginBudgetHelp },
  { path: '/user-maintenance/approval-groups/budgets', name: `Login Budgets`, component: LoginBudgets, helpCard: LoginBudgetsHelp },
  { path: '/admin/feature-flags/update/:key', name: `Update Feature Flag`, component: UpdateFeatureFlagView, helpCard: FeatureFlagHelp },
  { path: '/admin/feature-flags', name: `Feature Flags`, component: FeatureFlagsView, helpCard: FeatureFlagsViewHelp },
  { path: '/admin/users/update/:id', name: `Update Console User`, component: UpdateAzureUserView, helpCard: AzureUserFormHelp },
  { path: '/admin/users/create', name: `Invite Console User`, component: CreateAzureUserView, helpCard: AzureUserFormHelp },
  { path: '/admin/users', name: `Console Users`, component: AzureUsersView, helpCard: AzureUsersViewHelp },
  { path: '/admin/company-info', name: `Update Company Info`, component: UpdateCompanyInfo, helpCard: CompanyInfoHelp },
  { path: '/redirect', name: 'Redirect', component: Redirect }
];

export default routes;
